const validEmail = new RegExp(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
);

// Get better password regex before forcing this conditions
// Also make sure to display conditions during password creation
const validPassword = new RegExp('^(?=.*?[A-Za-z])(?=.*?[0-9]).{6,}$');

export const isValidEmail = (email: string | undefined): boolean => {
    if (!email || email.trim().length === 0) {
        return false;
    }
    return validEmail.test(email)
}

export const isValidPassword = (password: string | undefined): boolean => {
    if (!password || password.trim().length === 0) {
        return false;
    }
    return validPassword.test(password);
}