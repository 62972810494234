import { makeAutoObservable } from "mobx";
import { FirebaseApp, initializeApp } from 'firebase/app';
import { FirebaseConfig } from '../../config/firebase';

class FirebaseInitService {
    app: FirebaseApp;

    constructor() {
        makeAutoObservable(this);
        this.app = initializeApp(FirebaseConfig);
    }
}

export default FirebaseInitService;