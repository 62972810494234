import { makeAutoObservable, observable, action } from 'mobx';

export enum AlertSeverity {
    ERROR = 'error',
    WARNING = 'warning',
    INFO = 'info',
    SUCCESS = 'success',
}

class AlertStore {
    @observable msg: string = '';
    @observable open: boolean = false;
    @observable severity: AlertSeverity = AlertSeverity.INFO;

    constructor() {
        makeAutoObservable(this);
    }

    @action
    error = (msg: string) => {
        console.error(msg);
        this.msg = msg;
        this.open = true;
        this.severity = AlertSeverity.ERROR;
    }

    @action
    warn = (msg: string) => {
        console.warn(msg);
        this.msg = msg;
        this.open = true;
        this.severity = AlertSeverity.WARNING;
    }

    @action
    info = (msg: string) => {
        console.info(msg);
        this.msg = msg;
        this.open = true;
        this.severity = AlertSeverity.INFO;
    }

    @action
    success = (msg: string) => {
        console.info(msg);
        this.msg = msg;
        this.open = true;
        this.severity = AlertSeverity.SUCCESS;
    }

}

export default AlertStore;