import React from 'react';
import { observer } from 'mobx-react-lite';
import { AppBar, Button, Container, Divider, Toolbar, Typography } from '@mui/material';
import { CrowdSaveWordmark } from './logo';
import { useHistory } from 'react-router';
import { urlPaths } from './routes';

export const PageFooter: React.FC = observer(() => {
    const history = useHistory();

    const handleHome = () => {
        history.push(urlPaths.home);
    }

    return (
        <footer>
            <AppBar position="static" color="primary">
                <Container maxWidth="xl">
                    <Toolbar sx={{ paddingLeft: 0, paddingRight: 0 }}>
                        <Button onClick={handleHome} sx={{ p: 0, m: 0 }}>
                            <CrowdSaveWordmark height={20} logoColor='white' />
                        </Button>
                    </Toolbar>
                    <Divider color='secondary.main' />
                    <Toolbar sx={{ paddingLeft: 0, paddingRight: 0 }}>
                        <Typography variant="body1" color='primary.contrastText'>
                            © {(new Date()).getFullYear()} CrowdSave Inc.
                        </Typography>
                    </Toolbar>
                </Container>
            </AppBar>
        </footer>
    );
});
