import React, { useContext, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useHistory } from 'react-router';
import { RootStoreContext } from '../../stores/context';
import { Credentials } from '../../interfaces/user';
import { urlPaths } from '../../layouts/routes';
import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    Container,
    FormControl,
    TextField,
    Typography
} from "@mui/material";
import { Loader } from '../loader/Loader';
import { useStyles } from '../../layouts/styles';

const Login: React.FC = observer(() => {
    const { alertStore, authStore } = useContext(RootStoreContext);
    const history = useHistory();
    const [loading, setLoading] = React.useState(false);
    const [credentials, setCredentials] = React.useState<Credentials>({ email: '', password: '' });
    const [error, setError] = React.useState<string | undefined>(undefined);

    const classes = useStyles();

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const name = event.target.name;
        setCredentials({ ...credentials, [name]: event.target.value });
    }

    const handleLogin = () => {
        setLoading(true);
        if (!credentials.email || !credentials.password) {
            setLoading(false);
            setError('No credentials provided!');
            return;
        }
        authStore.signIn(credentials.email, credentials.password)
            .then(() => {
                alertStore.success('You are signed in.');
                history.push(urlPaths.home);
            })
            .catch((err) => {
                setLoading(false);
                const errorMessage = 'Incorrect credentials provided!';
                alertStore.error(errorMessage);
                setError(errorMessage);
            });
    };

    const handleCreateUser = () => {
        history.push(urlPaths.auth.create.user);
    };

    const handleCreateBusinessUser = () => {
        history.push(urlPaths.auth.create.business);
    };

    const handleResetEmail = () => {
        history.push(urlPaths.auth.reset);
    };

    useEffect(() => {
        if (authStore.isAuthenticated) {
            history.push(urlPaths.home);
        }
    }, [authStore.isAuthenticated, history]);

    return (
        <Container className={classes.CenteredHalfContainer}>
            <Box sx={{ p: 2 }} />
            <Card>
                <CardContent>
                    <Box sx={{ p: 1 }} />
                    <Typography color='error' gutterBottom>
                        {error ?? ''}
                    </Typography>
                    <FormControl sx={{ width: '100%' }}>
                        <TextField required type="email" id="email" name="email" label="Email" variant="outlined" onChange={handleInputChange} value={credentials.email} />
                        <Box sx={{ p: 1 }} />
                        <TextField required type="password" id="password" name="password" label="Password" variant="outlined" onChange={handleInputChange} value={credentials.password} />
                    </FormControl>
                </CardContent>
                <CardContent>
                    <Button variant="contained" color="secondary" onClick={handleLogin}>Sign In</Button>
                    <Loader loading={loading} />
                </CardContent>
                <CardActions sx={{ ml: 1 }}>
                    <Typography gutterBottom variant="subtitle1" component="div">
                        Don't have an account?
                    </Typography>
                    <Box sx={{ p: 1 }} />
                    <Button variant="contained" color="primary" size="small" onClick={handleCreateUser}>Register</Button>
                </CardActions>
                <CardActions sx={{ ml: 1 }}>
                    <Typography gutterBottom variant="subtitle1" component="div">
                        Are you a business owner?
                    </Typography>
                    <Box sx={{ p: 1 }} />
                    <Button variant="contained" color="primary" size="small" onClick={handleCreateBusinessUser}>Register your Business</Button>
                </CardActions>
                <CardActions sx={{ ml: 1 }}>
                    <Typography gutterBottom variant="subtitle1" component="div">
                        Forgot your password?
                    </Typography>
                    <Box sx={{ p: 1 }} />
                    <Button variant="contained" color="primary" size="small" onClick={handleResetEmail}>Reset Password</Button>
                </CardActions>
            </Card>
        </Container>
    );
});

export default Login;
