import React, { Suspense } from 'react';
import './App.css';
import { observer } from 'mobx-react-lite';
import AlertBar from './components/alert/AlertBar';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { Loader } from './components/loader/Loader';
import { AppRoute, routes } from './layouts/routes';
import PrivateAccess from './components/auth/PrivateAccess';

const RedirectHome: React.FC = () => {
    return (
        <Route render={() => {
            return (<Redirect to='/' />)
        }} />
    )
}

const App: React.FC = observer(() => {
    return (
        <div className="App">
            <Router>
                <Suspense fallback={<Loader loading={true} />}>
                    <Switch>
                        {routes.map((route: AppRoute, key) => {
                            if (route.isSecured) {
                                return (
                                    <PrivateAccess key={key}>
                                        <Route exact key={key} path={route.urlPath} component={route.component} />
                                    </PrivateAccess>
                                )
                            } else {
                                return (
                                    <Route exact key={key} path={route.urlPath} component={route.component} />
                                )
                            }
                        })}
                        <RedirectHome />
                    </Switch>
                </Suspense>
            </Router>
            <AlertBar />
        </div>
    );
});

export default App;
