import {
    Box,
    Grid,
    Button,
    Card,
    CardContent,
    Container,
    FormControl,
    TextField,
    Typography,
    Stepper,
    Step,
    StepLabel,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    FormControlLabel,
    Switch,
    Collapse,
    Select,
    MenuItem,
    SelectChangeEvent,
    OutlinedInput,
    Chip,
    InputLabel,
    Checkbox,
    Stack,
} from "@mui/material";
import { observer } from "mobx-react-lite";
import React, { useContext } from "react";
import { NewUserAddressInput, UserModel, UserRole } from "../../interfaces/user";
import { NewBusinessUserInput, NewBusinessDetailInput, NewBusiness, ServicesList, AreasList } from "../../interfaces/business";
import { RootStoreContext } from "../../stores/context";
import { urlPaths } from "../../layouts/routes";
import { useHistory } from "react-router";
import { useStyles } from "../../layouts/styles";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const componentSteps = ['Credentials', 'Business Address', 'Business Details'];

const CreateBusinessUser: React.FC = observer(() => {
    const { alertStore, authStore, userStore, businessStore } = useContext(RootStoreContext);
    const history = useHistory();
    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(0);
    const [completed, setCompleted] = React.useState<{ [k: number]: boolean; }>({});

    const [newBusinessUserInput, setNewBusinessInput] = React.useState<NewBusinessUserInput>({ businessName: '', firstName: '', lastName: '', email: '', password: '', confirmPassword: '' });
    const [newBusinessAddressInput, setNewBusinessAddressInput] = React.useState<NewUserAddressInput>({ unit: '', line1: '', city: '', postalCode: '', country: 'Canada' });
    const [newMailingAddressInput, setNewMailingAddressInput] = React.useState<NewUserAddressInput>({ unit: '', line1: '', city: '', postalCode: '', country: 'Canada' });
    const [newBusinessDetailInput, setNewBusinessDetailInput] = React.useState<NewBusinessDetailInput>({ services: [], areas: [] });
    const [error, setError] = React.useState<string | undefined>(undefined);
    const [checked, setChecked] = React.useState(true);


    if (authStore.isAuthenticated) {
        history.push(urlPaths.home);
    }

    const totalSteps = () => {
        return componentSteps.length;
    };

    const completedSteps = () => {
        return Object.keys(completed).length;
    };

    const isLastStep = () => {
        return activeStep === totalSteps() - 1;
    };

    const allStepsCompleted = () => {
        return completedSteps() === totalSteps();
    };

    const handleChange = () => {
        setChecked((prev) => !prev);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleCompleteStep = () => {
        const newCompleted = completed;
        newCompleted[activeStep] = true;
        setCompleted(newCompleted);
    };

    const handleNewBusinessInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const name = event.target.name;
        setNewBusinessInput({ ...newBusinessUserInput, [name]: event.target.value });
    }

    const handleNewBusinessAddressInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const name = event.target.name;
        setNewBusinessAddressInput({ ...newBusinessAddressInput, [name]: event.target.value });
    }

    const handleNewMailingAddressInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const name = event.target.name;
        setNewMailingAddressInput({ ...newMailingAddressInput, [name]: event.target.value });
    }

    const handleNewBusinessDetailInputChange = (type: 'services' | 'areas') => (event: SelectChangeEvent<string[]>) => {
        const value = event.target.value;
        setNewBusinessDetailInput({ ...newBusinessDetailInput, [type]: value === 'string' ? value.split(',') : value });
    };

    const handleError = (err: any) => {
        const errorMessage = err?.message || 'Incorrect credentials provided!';
        setError(errorMessage);
        console.debug('>> Auth error', err);
        alertStore.error(errorMessage);
    }

    const handleNext = () => {
        setError(undefined);
        if (activeStep === 0) {
            if (!newBusinessUserInput.businessName
                || !newBusinessUserInput.email
                || !newBusinessUserInput.firstName
                || !newBusinessUserInput.lastName
                || !newBusinessUserInput.password
                || newBusinessUserInput.password.length < 6
                || !newBusinessUserInput.confirmPassword
                || newBusinessUserInput.password !== newBusinessUserInput.confirmPassword) {
                handleError(new Error('Incorrect credentials provided!'))
                return;
            } else {
                handleCompleteStep();
            }
        } else if (activeStep === 1) {
            console.log('>> New Business AddressInput', newBusinessAddressInput)
            if (!newBusinessAddressInput.line1
                || !newBusinessAddressInput.postalCode
                || !newBusinessAddressInput.city
                || !newBusinessAddressInput.province
                || !newBusinessAddressInput.country
                || (!checked
                    && (
                        !newMailingAddressInput.line1
                        || !newMailingAddressInput.postalCode
                        || !newMailingAddressInput.city
                        || !newMailingAddressInput.province
                        || !newMailingAddressInput.country
                    ))) {
                handleError(new Error('Incorrect address provided!'))
                return;
            } else {
                handleCompleteStep();
            }
        } else if (activeStep === 2) {
            console.log('>> New Business Detail Input', newBusinessDetailInput)
            if (newBusinessDetailInput.areas.length <= 0
                || newBusinessDetailInput.services.length <= 0) {
                handleError(new Error('Incorrect business details provided!'))
                return;
            } else {
                handleCompleteStep();
            }
        }
        const newActiveStep =
            isLastStep() && !allStepsCompleted()
                ? // It's the last step, but not all steps have been completed,
                // find the first step that has been completed
                componentSteps.findIndex((step, i) => !(i in completed))
                : activeStep + 1;
        setActiveStep(newActiveStep);
    };

    const handleCreateBusinessUser = () => {
        const successMessage = 'Business created successfully!';
        authStore.createUser(newBusinessUserInput.email, newBusinessUserInput.password)
            .then((userCredential) => {
                const {
                    uid,
                    email,
                } = userCredential.user;

                const { businessName } = newBusinessUserInput;

                const newBusinessUser = {
                    uid,
                    email,
                    address: newBusinessAddressInput,
                    displayName: newBusinessUserInput.businessName,
                    roles: [UserRole.BUSINESS],
                } as UserModel;
                console.debug('>> New Business User to add', newBusinessUser);

                userStore.createUser(newBusinessUser)
                    .then((userId: string) => {
                        console.debug('>> Newly Created User ID', userId);
                        const newBusiness = { businessName, ownerId: userId, address: newBusinessAddressInput, mailing: newMailingAddressInput, ...newBusinessDetailInput } as NewBusiness;
                        console.debug('>> New Business to add', newBusiness);
                        businessStore.createBusiness(newBusiness)
                            .then((business: any) => {
                                console.debug('>> Newly Created Business', business);
                                authStore.sendVerificationEmail();
                                alertStore.success(successMessage);
                                history.push(urlPaths.home);
                            })
                            .catch(handleError);
                    })
                    .catch(handleError);
            })
            .catch(handleError);
    };

    return (
        <Container className={classes.CenteredContainer}>
            <Box sx={{ p: 2 }} />
            <Stepper nonLinear activeStep={activeStep}>
                <Step completed={completed[0]}>
                    <StepLabel>{componentSteps[0]}</StepLabel>
                </Step>

                <Step completed={completed[1]}>
                    <StepLabel>{componentSteps[1]}</StepLabel>
                </Step>

                <Step completed={completed[2]}>
                    <StepLabel>{componentSteps[2]}</StepLabel>
                </Step>

                <Step completed={completed[0] && completed[1] && completed[2]}>
                    <StepLabel>Review</StepLabel>
                </Step>
            </Stepper>

            <Box sx={{ p: 2 }} />
            {(activeStep === 0) && (
                <Card>
                    <CardContent>
                        <Box sx={{ p: 1 }} />
                        <Typography color='error' gutterBottom>
                            {error ?? ''}
                        </Typography>
                        <FormControl sx={{ width: '100%' }}>
                            <TextField required id="businessName" name="businessName" label="Business Name" variant="outlined" onChange={handleNewBusinessInputChange} value={newBusinessUserInput.businessName} />
                            <Box sx={{ p: 1 }} />
                            <Grid container>
                                <Grid item xs={6}>
                                    <TextField sx={{ width: '97%', mr: '3%' }} required id="firstName" name="firstName" label="First name" variant="outlined" onChange={handleNewBusinessInputChange} value={newBusinessUserInput.firstName} />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField sx={{ width: '97%', ml: '3%' }} required id="lastName" name="lastName" label="Last name" variant="outlined" onChange={handleNewBusinessInputChange} value={newBusinessUserInput.lastName} />
                                </Grid>
                            </Grid>
                            <Box sx={{ p: 1 }} />
                            <TextField required type="email" id="email" name="email" label="Email" variant="outlined" onChange={handleNewBusinessInputChange} value={newBusinessUserInput.email} />
                            <Box sx={{ p: 1 }} />
                            <TextField required type="password" id="password" name="password" label="Password (min. 6 character)" variant="outlined" onChange={handleNewBusinessInputChange} value={newBusinessUserInput.password} />
                            <Box sx={{ p: 1 }} />
                            <TextField required type="password" id="confirm-password" name="confirmPassword" label="Confirm Password (same as above)" variant="outlined" onChange={handleNewBusinessInputChange} value={newBusinessUserInput.confirmPassword} />
                        </FormControl>
                    </CardContent>
                </Card>
            )}

            {(activeStep === 1) && (
                <Card>
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                            {componentSteps[1]}
                        </Typography>
                        <Box sx={{ p: 1 }} />
                        <Typography color='error' gutterBottom>
                            {error ?? ''}
                        </Typography>
                        <FormControl sx={{ width: '100%' }}>
                            <Grid container>
                                <Grid item xs={10}>
                                    <TextField sx={{ width: '97%', mr: '3%' }} required id="line1" name="line1" label="Address Line 1" variant="outlined" onChange={handleNewBusinessAddressInputChange} value={newBusinessAddressInput.line1} />
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField sx={{ width: '97%', ml: '3%' }} id="unit" name="unit" label="Unit #" variant="outlined" onChange={handleNewBusinessAddressInputChange} value={newBusinessAddressInput.unit} />
                                </Grid>
                            </Grid>
                            <Box sx={{ p: 1 }} />
                            <TextField id="line2" name="line2" label="Address Line 2 (optional)" variant="outlined" onChange={handleNewBusinessAddressInputChange} value={newBusinessAddressInput.line2} />
                            <Box sx={{ p: 1 }} />
                            <Grid container>
                                <Grid item xs={6}>
                                    <TextField required sx={{ width: '97%', mr: '3%' }} id="city" name="city" label="City" variant="outlined" onChange={handleNewBusinessAddressInputChange} value={newBusinessAddressInput.city} />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField required sx={{ width: '97%', ml: '3%' }} id="province" name="province" label="Province" variant="outlined" onChange={handleNewBusinessAddressInputChange} value={newBusinessAddressInput.province} />
                                </Grid>
                            </Grid>
                            <Box sx={{ p: 1 }} />
                            <Grid container>
                                <Grid item xs={6}>
                                    <TextField required sx={{ width: '97%', mr: '3%' }} id="postalCode" name="postalCode" label="Postal Code" variant="outlined" onChange={handleNewBusinessAddressInputChange} value={newBusinessAddressInput.postalCode} />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField disabled sx={{ width: '97%', ml: '3%' }} id="country" name="country" label="Country" variant="outlined" onChange={handleNewBusinessAddressInputChange} value={newBusinessAddressInput.country} />
                                </Grid>
                            </Grid>
                            <Box sx={{ p: 1 }} />
                            <Box sx={{ p: 1 }} />
                            <Typography gutterBottom variant="h5" component="div">
                                Mailing Address
                            </Typography>
                            <FormControlLabel
                                control={<Switch checked={checked} onChange={handleChange} />}
                                label="Same As Above"
                            />
                            <Box sx={{ p: 1 }} />
                            <Box sx={{ display: 'flex' }}>
                                <Collapse in={!checked}>
                                    <Grid container>
                                        <Grid container>
                                            <Grid item xs={10}>
                                                <TextField sx={{ width: '97%', mr: '3%' }} required id="line1" name="line1" label="Address Line 1" variant="outlined" onChange={handleNewMailingAddressInputChange} value={newMailingAddressInput.line1} />
                                            </Grid>
                                            <Grid item xs={2}>
                                                <TextField sx={{ width: '97%', ml: '3%' }} id="unit" name="unit" label="Unit #" variant="outlined" onChange={handleNewMailingAddressInputChange} value={newMailingAddressInput.unit} />
                                            </Grid>
                                        </Grid>
                                        <Box sx={{ p: 1 }} />
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <TextField sx={{ width: '100%' }} id="line2" name="line2" label="Address Line 2 (optional)" variant="outlined" onChange={handleNewMailingAddressInputChange} value={newMailingAddressInput.line2} />
                                            </Grid>
                                        </Grid>
                                        <Box sx={{ p: 1 }} />
                                        <Grid container>
                                            <Grid item xs={6}>
                                                <TextField required sx={{ width: '97%', mr: '3%' }} id="city" name="city" label="City" variant="outlined" onChange={handleNewMailingAddressInputChange} value={newMailingAddressInput.city} />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField required sx={{ width: '97%', ml: '3%' }} id="province" name="province" label="Province" variant="outlined" onChange={handleNewMailingAddressInputChange} value={newMailingAddressInput.province} />
                                            </Grid>
                                        </Grid>
                                        <Box sx={{ p: 1 }} />
                                        <Grid container>
                                            <Grid item xs={6}>
                                                <TextField required sx={{ width: '97%', mr: '3%' }} id="postalCode" name="postalCode" label="Postal Code" variant="outlined" onChange={handleNewMailingAddressInputChange} value={newMailingAddressInput.postalCode} />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField disabled sx={{ width: '97%', ml: '3%' }} id="country" name="country" label="Country" variant="outlined" onChange={handleNewMailingAddressInputChange} value={newMailingAddressInput.country} />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Collapse>
                            </Box>
                        </FormControl>
                    </CardContent>
                </Card>
            )}

            {(activeStep === 2) && (
                <Card>
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                            {componentSteps[2]}
                        </Typography>
                        <Box sx={{ p: 1 }} />
                        <Typography color='error' gutterBottom>
                            {error ?? ''}
                        </Typography>
                        <FormControl sx={{ width: '100%' }}>
                            <Grid container>
                                <InputLabel id="providedServicesLabel">Provided Services</InputLabel>
                                <Select
                                    id="providedServices"
                                    name="providedServices"
                                    labelId="providedServicesLabel"
                                    label="Provided Services"
                                    sx={{ width: '100%' }}
                                    variant="outlined"
                                    value={newBusinessDetailInput.services}
                                    multiple
                                    onChange={handleNewBusinessDetailInputChange('services')}
                                    input={<OutlinedInput id="providedServicesLabelChip" label="Provided Services" />}
                                    renderValue={(selected) => (
                                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                            {selected.map((value) => (
                                                <Chip key={value} label={value} />
                                            ))}
                                        </Box>
                                    )}
                                    MenuProps={MenuProps}
                                >
                                    {ServicesList.map((s) => (
                                        <MenuItem value={s}>{s}</MenuItem>
                                    ))}
                                </Select>
                            </Grid>
                        </FormControl>
                        <Box sx={{ p: 1 }} />
                        <FormControl sx={{ width: '100%' }}>
                            <Grid container>
                                <InputLabel id="serviceAreasLabel">Service Areas</InputLabel>
                                <Select
                                    id="serviceAreas"
                                    name="serviceAreas"
                                    labelId="serviceAreasLabel"
                                    label="Service Areas"
                                    sx={{ width: '100%' }}
                                    variant="outlined"
                                    value={newBusinessDetailInput.areas}
                                    multiple
                                    onChange={handleNewBusinessDetailInputChange('areas')}
                                    input={<OutlinedInput id="serviceAreasLabelChip" label="Service Areas" />}
                                    renderValue={(selected) => (
                                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                            {selected.map((value) => (
                                                <Chip key={value} label={value} />
                                            ))}
                                        </Box>
                                    )}
                                    MenuProps={MenuProps}
                                >
                                    {AreasList.map((s) => (
                                        <MenuItem value={s}>{s}</MenuItem>
                                    ))}
                                </Select>
                            </Grid>
                        </FormControl>
                    </CardContent>
                </Card>
            )}

            {activeStep === 3 && (
                <Card>
                    <CardContent>
                        <div>
                            <Accordion expanded>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                    <Typography>Business Profile</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <TextField
                                        label="Business Name"
                                        defaultValue={newBusinessUserInput.businessName}
                                        sx={{ width: '100%' }}
                                        InputProps={{ readOnly: true, }} />

                                    <Box sx={{ p: 1 }} />
                                    <TextField
                                        label="Full Name"
                                        defaultValue={newBusinessUserInput.firstName + ' ' + newBusinessUserInput.lastName}
                                        sx={{ width: '100%' }}
                                        InputProps={{ readOnly: true, }} />

                                    <Box sx={{ p: 1 }} />
                                    <TextField
                                        label="Email Address"
                                        defaultValue={newBusinessUserInput.email}
                                        sx={{ width: '100%' }}
                                        InputProps={{ readOnly: true, }} />
                                </AccordionDetails>
                            </Accordion>
                            <Accordion>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                    <Typography>Business Address</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container>
                                        <Grid item xs={10}>
                                            <TextField
                                                label="Address Line 1"
                                                defaultValue={newBusinessAddressInput.line1}
                                                sx={{ width: '97%', mr: '3%' }}
                                                InputProps={{ readOnly: true, }} />
                                        </Grid>

                                        <Grid item xs={2}>
                                            <TextField
                                                label="Unit"
                                                defaultValue={newBusinessAddressInput.unit}
                                                sx={{ width: '97%', ml: '3%' }}
                                                InputProps={{ readOnly: true, }} />
                                        </Grid>

                                    </Grid>
                                    <Box sx={{ p: 1 }} />
                                    <TextField
                                        label="Address Line 2"
                                        defaultValue={newBusinessAddressInput.line2}
                                        sx={{ width: '100%' }}
                                        InputProps={{ readOnly: true, }} />

                                    <Box sx={{ p: 1 }} />
                                    <Grid container>
                                        <Grid item xs={6}>
                                            <TextField
                                                label="City"
                                                defaultValue={newBusinessAddressInput.city}
                                                sx={{ width: '97%', mr: '3%' }}
                                                InputProps={{ readOnly: true, }} />
                                        </Grid>

                                        <Grid item xs={6}>
                                            <TextField
                                                label="Province"
                                                defaultValue={newBusinessAddressInput.province}
                                                sx={{ width: '97%', ml: '3%' }}
                                                InputProps={{ readOnly: true, }} />
                                        </Grid>

                                    </Grid>
                                    <Box sx={{ p: 1 }} />

                                    <Grid container>
                                        <Grid item xs={6}>
                                            <TextField
                                                label="Postal Code"
                                                defaultValue={newBusinessAddressInput.postalCode}
                                                sx={{ width: '97%', mr: '3%' }}
                                                InputProps={{ readOnly: true, }} />
                                        </Grid>

                                        <Grid item xs={6}>
                                            <TextField
                                                label="Country"
                                                defaultValue={newBusinessAddressInput.country}
                                                sx={{ width: '97%', ml: '3%' }}
                                                InputProps={{ readOnly: true, }} />
                                        </Grid>

                                    </Grid>
                                    <Box sx={{ p: 1 }} />
                                </AccordionDetails>
                            </Accordion>
                            <Accordion>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                    <Typography>Mailing Address</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    {checked ? (
                                        <FormControlLabel disabled control={<Checkbox checked />} label="Same as Business Address" />
                                    ) : (
                                        <Grid container>
                                            <Grid container>
                                                <Grid item xs={10}>
                                                    <TextField
                                                        label="Address Line 1"
                                                        defaultValue={newMailingAddressInput.line1}
                                                        sx={{ width: '97%', mr: '3%' }}
                                                        InputProps={{ readOnly: true, }} />
                                                </Grid>

                                                <Grid item xs={2}>
                                                    <TextField
                                                        label="Unit"
                                                        defaultValue={newMailingAddressInput.unit}
                                                        sx={{ width: '97%', ml: '3%' }}
                                                        InputProps={{ readOnly: true, }} />
                                                </Grid>

                                            </Grid>
                                            <Box sx={{ p: 1 }} />
                                            <TextField
                                                label="Address Line 2"
                                                defaultValue={newMailingAddressInput.line2}
                                                sx={{ width: '100%' }}
                                                InputProps={{ readOnly: true, }} />

                                            <Box sx={{ p: 1 }} />
                                            <Grid container>
                                                <Grid item xs={6}>
                                                    <TextField
                                                        label="City"
                                                        defaultValue={newMailingAddressInput.city}
                                                        sx={{ width: '97%', mr: '3%' }}
                                                        InputProps={{ readOnly: true, }} />
                                                </Grid>

                                                <Grid item xs={6}>
                                                    <TextField
                                                        label="Province"
                                                        defaultValue={newMailingAddressInput.province}
                                                        sx={{ width: '97%', ml: '3%' }}
                                                        InputProps={{ readOnly: true, }} />
                                                </Grid>

                                            </Grid>
                                            <Box sx={{ p: 1 }} />

                                            <Grid container>
                                                <Grid item xs={6}>
                                                    <TextField
                                                        label="Postal Code"
                                                        defaultValue={newMailingAddressInput.postalCode}
                                                        sx={{ width: '97%', mr: '3%' }}
                                                        InputProps={{ readOnly: true, }} />
                                                </Grid>

                                                <Grid item xs={6}>
                                                    <TextField
                                                        label="Country"
                                                        defaultValue={newMailingAddressInput.country}
                                                        sx={{ width: '97%', ml: '3%' }}
                                                        InputProps={{ readOnly: true, }} />
                                                </Grid>

                                            </Grid>
                                        </Grid>
                                    )}
                                </AccordionDetails>
                            </Accordion>
                            <Accordion>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                    <Typography>Services Provided</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container>
                                        <Stack direction="row" spacing={1}>
                                            {newBusinessDetailInput.services.map(value => (
                                                <Chip color="primary" key={value} label={value} />
                                            ))}
                                        </Stack>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                    <Typography>Areas Serviced</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container>
                                        <Stack direction="row" spacing={1}>
                                            {newBusinessDetailInput.areas.map(value => (
                                                <Chip color="primary" key={value} label={value} />
                                            ))}
                                        </Stack>
                                    </Grid>
                                    <Box sx={{ p: 1 }} />
                                </AccordionDetails>
                            </Accordion>
                        </div>
                    </CardContent>
                </Card>
            )}

            <div>
                <React.Fragment>
                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                        <Button
                            variant="outlined"
                            color="warning"
                            disabled={activeStep === 0}
                            onClick={handleBack}
                            sx={{ mr: 1 }}
                        >
                            Back
                        </Button>
                        <Box sx={{ flex: '1 1 auto' }} />

                        {(activeStep === 3 && allStepsCompleted()) ?
                            (
                                <Button variant="contained" color="secondary" onClick={handleCreateBusinessUser}>
                                    Complete
                                </Button>
                            )
                            : (completedSteps() === totalSteps() - 1) ?
                                (
                                    <Button variant="outlined" color="secondary" onClick={handleNext}>
                                        Review
                                    </Button>
                                )
                                : (
                                    <Button variant="outlined" color="secondary" onClick={handleNext}>
                                        Next
                                    </Button>
                                )}
                    </Box>
                </React.Fragment>
            </div>

            <div>
                {(activeStep === 3 && allStepsCompleted()) ?
                (
                    <React.Fragment>
                        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                            <Box sx={{ flex: '1 1 auto' }} />
                            <Typography variant="subtitle2" color="primary" sx={{ fontStyle: 'italic', }} >
                                *A verification email will be sent to the email address you provided.
                            </Typography>
                            <Box sx={{ flex: '1 1 auto' }} />
                        </Box>
                    </React.Fragment>
                )
                : <></> }
            </div>
        </Container>
    )
});
export default CreateBusinessUser;