import UserStore from './user';
import AlertStore from './alert';
import FirebaseInitService from '../services/firebase/init';
import AuthStore from './auth';
import MediaStore from './media';
import FirebaseAuthService from '../services/firebase/auth';
import FirebaseMediaStorageService from '../services/firebase/media';
import FirebaseDatabaseService from '../services/firebase/database';
import BusinessStore from './business';
import SubscriberStore from './subscriber';
import AnalyticsStore from './analytics';
import FirebaseAnalyticsService from '../services/firebase/analytics';

export class RootStore {
    public authStore: AuthStore;
    public mediaStore: MediaStore;
    public alertStore: AlertStore;
    public userStore: UserStore;
    public businessStore: BusinessStore;
    public subscriberStore: SubscriberStore
    public analyticsStore: AnalyticsStore;

    constructor() {
        this.alertStore = new AlertStore();

        const firebaseInitService = new FirebaseInitService();
        const firebaseAuthService = new FirebaseAuthService();
        const firebaseDatabaseService = new FirebaseDatabaseService(firebaseInitService);
        const firebaseMediaStorageService = new FirebaseMediaStorageService(firebaseInitService);
        const firebaseAnalyticsService = new FirebaseAnalyticsService(firebaseInitService);

        this.authStore = new AuthStore(firebaseAuthService);
        this.mediaStore = new MediaStore(firebaseMediaStorageService);
        this.userStore = new UserStore(firebaseDatabaseService);
        this.businessStore = new BusinessStore(firebaseDatabaseService);
        this.subscriberStore = new SubscriberStore(firebaseDatabaseService);
        this.analyticsStore = new AnalyticsStore(firebaseAnalyticsService);
    }
}
export const rootStore = new RootStore();