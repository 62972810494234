import React, { useContext } from 'react';
import { observer } from 'mobx-react-lite';
import { Alert, Snackbar } from '@mui/material';
import { RootStoreContext } from '../../stores/context';

const AlertBar: React.FC = observer(() => {
    const { alertStore } = useContext(RootStoreContext);

    const handleClose = (_: any, reason: any) => {
        if (reason === 'clickaway') {
            return;
        }

        alertStore.open = false;
    };

    return (
        <Snackbar open={alertStore.open} autoHideDuration={4000} onClose={handleClose}>
            <Alert severity={alertStore.severity} sx={{ width: '100%' }}>
                {alertStore.msg}
            </Alert>
        </Snackbar>
    );
});

export default AlertBar;
