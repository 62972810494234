import FirebaseInitService from "./init";
import { makeAutoObservable } from "mobx";
import { Analytics, getAnalytics, logEvent, setUserProperties } from "firebase/analytics";
import { IAnalyticsService } from "../interfaces";

export enum AnalyticsEventType {
    LOGIN = 'login',
    PAGE_VIEW = 'page_view',
    SEARCH = 'search',
    SIGN_UP = 'sign_up',
}

class FirebaseAnalyticsService implements IAnalyticsService {
    analytics: Analytics;

    constructor(firebaseInitService: FirebaseInitService) {
        makeAutoObservable(this);
        this.analytics = getAnalytics(firebaseInitService.app);
    }

    log = (type: string) => {
        return logEvent(this.analytics, type);
    }

    logData = (type: string, data: any) => {
        return logEvent(this.analytics, type, data);
    }

    setUserProperties = (properties: any) => {
        return setUserProperties(this.analytics, properties);
    }

}

export default FirebaseAnalyticsService;