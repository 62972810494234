import { observer } from "mobx-react-lite";
import React from "react";
import CreateUser from "../../components/auth/CreateUser";
import { MainLayout } from "../../layouts/page";

const CreateUserPage: React.FC = observer(() => {

    return (
        <>
            <MainLayout>
                <CreateUser />
            </MainLayout>
        </>
    )
});
export default CreateUserPage;