import { Address } from "./address";
import { Socials } from "./common";

export enum UserRole {
    USER = 'User',
    ADMIN = 'Admin',
    OWNER = 'Owner',
    BUSINESS = 'Business',
}

export enum AuthStatus {
    UNKNOWN,
    SIGNED_IN,
    SIGNED_OUT,
}

export interface Credentials {
    email: string;
    password: string;
}

export interface NewCredentials extends ConfirmCredentials {
    email: string;
}

export interface ConfirmCredentials {
    password: string;
    confirmPassword: string;
}

export interface NewUserInput extends NewCredentials, NamedIdentity {}

export interface NewUserAddressInput extends Address {}

export interface NamedIdentity {
    firstName: string;
    middleName?: string;
    lastName: string;
}

export interface Profile extends User, NamedIdentity {
    phone: string;
    address: Address;
    socials?: Socials;
    dateOfBirth: number;
}

export interface User extends UserAuthDetails {
    roles: [UserRole];
}

export interface UserModel extends User {
    address: any;
}

export interface UserIdentity {
    uid: string;
    email: string;
}

export interface UserAuthDetails extends UserIdentity {
    photoURL: string;
    phoneNumber: string;
    displayName: string;
    isAnonymous: boolean;
    emailVerified: boolean;
}

export interface NewSubscriber {
    email: string;
    date: Date;
}