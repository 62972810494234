import { makeAutoObservable } from "mobx";
import { NewSubscriber } from "../interfaces/user";
import { WhereQuery } from "../services/firebase/database";
import { IDatabaseService } from "../services/interfaces";

const COLLECTION_NAME = 'subscribers';

class SubscriberStore {
    databaseService: IDatabaseService;

    constructor(databaseService: IDatabaseService) {
        makeAutoObservable(this);
        this.databaseService = databaseService;
    }

    getSubscriber = (email: string) => {
        return this.databaseService.queryDocument(COLLECTION_NAME, [new WhereQuery('email', '==', email)]);
    }

    addSubscriber = (subscriber: NewSubscriber) => {
        return this.databaseService.addDocument(COLLECTION_NAME, subscriber);
    }
}

export default SubscriberStore;