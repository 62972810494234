import { CircularProgress } from '@mui/material';
import React from 'react';

export interface LoaderProps {
    loading: boolean;
}

export const Loader: React.FC<LoaderProps> = ({ loading }) => {
    return (
        <>
            {loading && (
                <CircularProgress size={20} />
            )}
        </>
    );
};