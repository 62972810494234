import { observer } from "mobx-react-lite";
import React from "react";
import Login from "../../components/auth/Login";
import { MainLayout } from "../../layouts/page";

const LoginPage: React.FC = observer(() => {

    return (
        <>
            <MainLayout isAuthPage>
                <Login />
            </MainLayout>
        </>
    )
});
export default LoginPage;