import React from 'react';
import Logout from '../components/auth/Logout';
import CreateBusinessUserPage from '../pages/auth/CreateBusinessUserPage';
import CreateUserPage from '../pages/auth/CreateUserPage';
import LoginPage from '../pages/auth/LoginPage';
import SendPasswordResetPage from '../pages/auth/SendPasswordResetPage';
import LandingPage from '../pages/home/LandingPage';

export interface AppRoute extends AppNav {
    component: React.FC;
    isSecured: boolean;
    isPublic: boolean;
}

export interface AppNav {
    title: string;
    urlPath: string;
}

export const urlPaths = {
    home: '/',
    auth: {
        reset: '/auth/reset',
        login: '/auth/login',
        logout: '/auth/logout',
        signup: '/auth/signup',
        create: {
            user: '/auth/create/user',
            business: '/auth/create/business',
        }
    },
    landing: '/landing',
    dashboard: {
        home: '/dashboard',
        group: '/dashboard/groups'
    },
    settings: '/settings'
}

export const routes: AppRoute[] = [
    {
        title: "Login Page",
        urlPath: urlPaths.auth.login,
        component: LoginPage,
        isSecured: false,
        isPublic: true,
    },
    {
        title: "Logout Page",
        urlPath: urlPaths.auth.logout,
        component: Logout,
        isSecured: false,
        isPublic: true,
    },
    {
        title: "Password Reset Page",
        urlPath: urlPaths.auth.reset,
        component: SendPasswordResetPage,
        isSecured: false,
        isPublic: true,
    },
    {
        title: "Create User Page",
        urlPath: urlPaths.auth.create.user,
        component: CreateUserPage,
        isSecured: false,
        isPublic: true,
    },
    {
        title: "Create Business User Page",
        urlPath: urlPaths.auth.create.business,
        component: CreateBusinessUserPage,
        isSecured: false,
        isPublic: true,
    },
    {
        title: "Home Page",
        urlPath: urlPaths.home,
        component: LandingPage,
        isSecured: false,
        isPublic: false,
    },
    {
        title: "Landing Page",
        urlPath: urlPaths.landing,
        component: LandingPage,
        isSecured: false,
        isPublic: false,
    }
];

export const navs: AppNav[] = [
];