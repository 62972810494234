import { makeAutoObservable } from "mobx";
import { IMediaStorageService } from "../services/interfaces";

class MediaStore {
    storageService: IMediaStorageService;

    constructor(storageService: IMediaStorageService) {
        makeAutoObservable(this);
        this.storageService = storageService;
    }
}

export default MediaStore;