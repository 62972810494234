import {
    Box,
    Grid,
    Button,
    Card,
    CardContent,
    Container,
    FormControl,
    TextField,
    Typography,
    Stepper,
    Step,
    StepLabel,
    Accordion,
    AccordionSummary,
    AccordionDetails,
} from "@mui/material";
import { observer } from "mobx-react-lite";
import React, { useContext } from "react";
import { NewUserAddressInput, NewUserInput, UserModel, UserRole } from "../../interfaces/user";
import { RootStoreContext } from "../../stores/context";
import { urlPaths } from "../../layouts/routes";
import { useHistory } from "react-router";
import { useStyles } from "../../layouts/styles";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const componentSteps = ['Credentials', 'Address'];

const CreateUser: React.FC = observer(() => {
    const { alertStore, authStore, userStore } = useContext(RootStoreContext);
    const history = useHistory();
    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(0);
    const [completed, setCompleted] = React.useState<{ [k: number]: boolean; }>({});

    const [newUserInput, setNewUserInput] = React.useState<NewUserInput>({ firstName: '', lastName: '', email: '', password: '', confirmPassword: '' });
    const [newUserAddressInput, setNewUserAddressInput] = React.useState<NewUserAddressInput>({ unit: '', line1: '', city: '', postalCode: '', country: 'Canada' });
    const [error, setError] = React.useState<string | undefined>(undefined);

    if (authStore.isAuthenticated) {
        history.push(urlPaths.home);
    }

    const totalSteps = () => {
        return componentSteps.length;
    };

    const completedSteps = () => {
        return Object.keys(completed).length;
    };

    const isLastStep = () => {
        return activeStep === totalSteps() - 1;
    };

    const allStepsCompleted = () => {
        return completedSteps() === totalSteps();
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleCompleteStep = () => {
        const newCompleted = completed;
        newCompleted[activeStep] = true;
        setCompleted(newCompleted);
    };

    const handleNewUserInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const name = event.target.name;
        setNewUserInput({ ...newUserInput, [name]: event.target.value });
    }

    const handleNewUserAddressInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const name = event.target.name;
        setNewUserAddressInput({ ...newUserAddressInput, [name]: event.target.value });
    }

    const handleError = (err: any) => {
        const errorMessage = err?.message || 'Incorrect credentials provided!';
        setError(errorMessage);
        console.debug('>> Auth error', err);
        alertStore.error(errorMessage);
    }

    const handleNext = () => {
        if (activeStep === 0) {
            if (!newUserInput.email
                || !newUserInput.firstName
                || !newUserInput.lastName
                || !newUserInput.password
                || newUserInput.password.length < 6
                || !newUserInput.confirmPassword
                || newUserInput.password !== newUserInput.confirmPassword) {
                handleError(new Error('Incorrect credentials provided!'))
                return;
            } else {
                handleCompleteStep();
            }
        } else if (activeStep === 1) {
            console.log('>>newUserAddressInput', newUserAddressInput)
            if (!newUserAddressInput.line1
                || !newUserAddressInput.postalCode
                || !newUserAddressInput.city
                || !newUserAddressInput.province
                || !newUserAddressInput.country) {
                handleError(new Error('Incorrect address provided!'))
                return;
            } else {
                handleCompleteStep();
            }
        }
        const newActiveStep =
            isLastStep() && !allStepsCompleted()
                ? // It's the last step, but not all steps have been completed,
                // find the first step that has been completed
                componentSteps.findIndex((step, i) => !(i in completed))
                : activeStep + 1;
        setActiveStep(newActiveStep);
    };

    const handleCreateUser = () => {
        const successMessage = 'User created successfully!';
        authStore.createUser(newUserInput.email, newUserInput.password)
            .then((userCredential) => {
                const {
                    uid,
                    email,
                } = userCredential.user;

                const newUser = {
                    uid,
                    email,
                    displayName: [newUserInput.firstName, newUserInput.lastName].join(' '),
                    address: newUserAddressInput,
                    roles: [UserRole.USER],
                } as UserModel;
                console.debug('>> New User to add', newUser);

                userStore.createUser(newUser)
                    .then((userId: string) => {
                        console.debug('>> Newly Created User ID', userId);
                        authStore.sendVerificationEmail();
                        alertStore.success(successMessage);
                        history.push(urlPaths.home);
                    })
                    .catch(handleError);
            })
            .catch(handleError);
    };

    return (
        <Container className={classes.CenteredContainer}>
            <Box sx={{ p: 2 }} />
            <Stepper nonLinear activeStep={activeStep}>
                <Step completed={completed[0]}>
                    <StepLabel>{componentSteps[0]}</StepLabel>
                </Step>

                <Step completed={completed[1]}>
                    <StepLabel>{componentSteps[1]}</StepLabel>
                </Step>

                <Step completed={completed[0] && completed[1]}>
                    <StepLabel>Review</StepLabel>
                </Step>
            </Stepper>

            <Box sx={{ p: 2 }} />
            {(activeStep === 0) && (
                <Card>
                    <CardContent>
                        <Box sx={{ p: 1 }} />
                        <Typography color='error' gutterBottom>
                            {error ?? ''}
                        </Typography>
                        <FormControl sx={{ width: '100%' }}>
                            <Grid container>
                                <Grid item xs={6}>
                                    <TextField sx={{ width: '97%', mr: '3%' }} required id="firstName" name="firstName" label="First name" variant="outlined" onChange={handleNewUserInputChange} value={newUserInput.firstName} />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField sx={{ width: '97%', ml: '3%' }} required id="lastName" name="lastName" label="Last name" variant="outlined" onChange={handleNewUserInputChange} value={newUserInput.lastName} />
                                </Grid>
                            </Grid>
                            <Box sx={{ p: 1 }} />
                            <TextField required type="email" id="email" name="email" label="Email" variant="outlined" onChange={handleNewUserInputChange} value={newUserInput.email} />
                            <Box sx={{ p: 1 }} />
                            <TextField required type="password" id="password" name="password" label="Password (min. 6 character)" variant="outlined" onChange={handleNewUserInputChange} value={newUserInput.password} />
                            <Box sx={{ p: 1 }} />
                            <TextField required type="password" id="confirm-password" name="confirmPassword" label="Confirm Password (same as above)" variant="outlined" onChange={handleNewUserInputChange} value={newUserInput.confirmPassword} />
                        </FormControl>
                    </CardContent>
                </Card>
            )}

            {(activeStep === 1) && (
                <Card>
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                            {componentSteps[1]}
                        </Typography>
                        <Box sx={{ p: 1 }} />
                        <Typography color='error' gutterBottom>
                            {error ?? ''}
                        </Typography>
                        <FormControl sx={{ width: '100%' }}>
                            <Grid container>
                                <Grid item xs={10}>
                                    <TextField sx={{ width: '97%', mr: '3%' }} required id="line1" name="line1" label="Address Line 1" variant="outlined" onChange={handleNewUserAddressInputChange} value={newUserAddressInput.line1} />
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField sx={{ width: '97%', ml: '3%' }} id="unit" name="unit" label="Unit #" variant="outlined" onChange={handleNewUserAddressInputChange} value={newUserAddressInput.unit} />
                                </Grid>
                            </Grid>
                            <Box sx={{ p: 1 }} />
                            <TextField id="line2" name="line2" label="Address Line 2 (optional)" variant="outlined" onChange={handleNewUserAddressInputChange} value={newUserAddressInput.line2} />
                            <Box sx={{ p: 1 }} />
                            <Grid container>
                                <Grid item xs={6}>
                                    <TextField required sx={{ width: '97%', mr: '3%' }} id="city" name="city" label="City" variant="outlined" onChange={handleNewUserAddressInputChange} value={newUserAddressInput.city} />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField required sx={{ width: '97%', ml: '3%' }} id="province" name="province" label="Province" variant="outlined" onChange={handleNewUserAddressInputChange} value={newUserAddressInput.province} />
                                </Grid>
                            </Grid>
                            <Box sx={{ p: 1 }} />
                            <Grid container>
                                <Grid item xs={6}>
                                    <TextField required sx={{ width: '97%', mr: '3%' }} id="postalCode" name="postalCode" label="Postal Code" variant="outlined" onChange={handleNewUserAddressInputChange} value={newUserAddressInput.postalCode} />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField disabled sx={{ width: '97%', ml: '3%' }} id="country" name="country" label="Country" variant="outlined" onChange={handleNewUserAddressInputChange} value={newUserAddressInput.country} />
                                </Grid>
                            </Grid>
                            <Box sx={{ p: 1 }} />
                        </FormControl>
                    </CardContent>
                </Card>
            )}

            {activeStep === 2 && (
                <Card>
                    <CardContent>
                        <div>
                            <Accordion expanded>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                    <Typography>Profile</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <TextField
                                        label="Full Name"
                                        defaultValue={[newUserInput.firstName, newUserInput.lastName].join(' ')}
                                        sx={{ width: '100%' }}
                                        InputProps={{ readOnly: true, }} />

                                    <Box sx={{ p: 1 }} />
                                    <TextField
                                        label="Email Address"
                                        defaultValue={newUserInput.email}
                                        sx={{ width: '100%' }}
                                        InputProps={{ readOnly: true, }} />
                                </AccordionDetails>
                            </Accordion>
                            <Accordion>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                    <Typography>Address</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container>
                                        <Grid item xs={10}>
                                            <TextField
                                                label="Address Line 1"
                                                defaultValue={newUserAddressInput.line1}
                                                sx={{ width: '97%', mr: '3%' }}
                                                InputProps={{ readOnly: true, }} />
                                        </Grid>

                                        <Grid item xs={2}>
                                            <TextField
                                                label="Unit"
                                                defaultValue={newUserAddressInput.unit}
                                                sx={{ width: '97%', ml: '3%' }}
                                                InputProps={{ readOnly: true, }} />
                                        </Grid>

                                    </Grid>
                                    <Box sx={{ p: 1 }} />
                                    <TextField
                                        label="Address Line 2"
                                        defaultValue={newUserAddressInput.line2}
                                        sx={{ width: '100%' }}
                                        InputProps={{ readOnly: true, }} />

                                    <Box sx={{ p: 1 }} />
                                    <Grid container>
                                        <Grid item xs={6}>
                                            <TextField
                                                label="City"
                                                defaultValue={newUserAddressInput.city}
                                                sx={{ width: '97%', mr: '3%' }}
                                                InputProps={{ readOnly: true, }} />
                                        </Grid>

                                        <Grid item xs={6}>
                                            <TextField
                                                label="Province"
                                                defaultValue={newUserAddressInput.province}
                                                sx={{ width: '97%', ml: '3%' }}
                                                InputProps={{ readOnly: true, }} />
                                        </Grid>

                                    </Grid>
                                    <Box sx={{ p: 1 }} />

                                    <Grid container>
                                        <Grid item xs={6}>
                                            <TextField
                                                label="Postal Code"
                                                defaultValue={newUserAddressInput.postalCode}
                                                sx={{ width: '97%', mr: '3%' }}
                                                InputProps={{ readOnly: true, }} />
                                        </Grid>

                                        <Grid item xs={6}>
                                            <TextField
                                                label="Country"
                                                defaultValue={newUserAddressInput.country}
                                                sx={{ width: '97%', ml: '3%' }}
                                                InputProps={{ readOnly: true, }} />
                                        </Grid>

                                    </Grid>
                                    <Box sx={{ p: 1 }} />
                                </AccordionDetails>
                            </Accordion>
                        </div>
                    </CardContent>
                </Card>
            )}

            <div>
                <React.Fragment>
                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                        <Button
                            variant="outlined"
                            color="warning"
                            disabled={activeStep === 0}
                            onClick={handleBack}
                            sx={{ mr: 1 }}
                        >
                            Back
                        </Button>
                        <Box sx={{ flex: '1 1 auto' }} />

                        {(activeStep === 2 && allStepsCompleted()) ?
                            (
                                <Button variant="contained" color="secondary" onClick={handleCreateUser}>
                                    Complete
                                </Button>
                            )
                            : (completedSteps() === totalSteps() - 1) ?
                                (
                                    <Button variant="outlined" color="secondary" onClick={handleNext}>
                                        Review
                                    </Button>
                                )
                                : (
                                    <Button variant="outlined" color="secondary" onClick={handleNext}>
                                        Next
                                    </Button>
                                )}
                    </Box>
                </React.Fragment>
            </div>

            <div>
                {(activeStep === 2 && allStepsCompleted()) ?
                    (
                        <React.Fragment>
                            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                <Box sx={{ flex: '1 1 auto' }} />
                                <Typography variant="subtitle2" color="primary" sx={{ fontStyle: 'italic', }} >
                                    *A verification email will be sent to the email address you provided.
                                </Typography>
                                <Box sx={{ flex: '1 1 auto' }} />
                            </Box>
                        </React.Fragment>
                    )
                    : <></>}
            </div>
        </Container>
    )
});
export default CreateUser;