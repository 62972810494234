import { Address, Phone } from "./address";
import { Socials } from "./common";
import { Category, Service, ServiceArea } from "./group";
import { NewUserAddressInput, NewUserInput, Profile, User } from "./user";

export interface Business {
    name: string
    address: [Address]
    phone: [Phone]
    users: [User];
    serviceAreas: [ServiceArea]
    services: [Service]
    profiles?: [Profile]
    socials?: Socials
    description?: string
    shortDescription?: string
}

export interface NewBusiness extends NewBusinessDetailInput, NewBusinessAddressInput, NewBusinessUserInput {
    ownerId: any;
}

export interface Provider {
    business: Business;
    categories: [Category];
    description?: string;
    shortDescription?: string;
}

export interface NewBusinessUserInput extends NewUserInput {
    businessName: string;
    website?: string;
};

export interface NewBusinessAddressInput {
    address: NewUserAddressInput;
    mailing?: NewUserAddressInput;
};

export interface NewBusinessDetailInput {
    services: string[];
    areas: string[];
}

export const ServicesList: string[] = [
    'Cleaning services',
    'Construction and Renovation',
    'Drywall',
    'Electrical',
    'Electronics',
    'Home Energy Efficiency',
    'Home Inspection',
    'HVAC',
    'Insulation',
    'Landscaping',
    'Plumbing',
    'Roofing',
    'Snow Removal',
    'Tile and Stone',
    'Windows and Doors',
];

export const AreasList: string[] = [
    'Ajax',
    'Aurora',
    'Brampton',
    'Brock',
    'Burlington',
    'Caledon',
    'Clarington',
    'East Gwillimbury',
    'Georgina',
    'Halton Hills',
    'King',
    'Markham',
    'Milton',
    'Mississauga',
    'Newmarket',
    'Oakville',
    'Oshawa',
    'Pickering',
    'Richmond Hill',
    'Scugog',
    'Toronto',
    'Uxbridge',
    'Vaughan',
    'Whitby',
    'Whitchurch-Stouffville',
];