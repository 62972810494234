import React, { useContext, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useHistory } from 'react-router';
import { RootStoreContext } from '../../stores/context';
import { urlPaths } from '../../layouts/routes';
import {
    Box,
    Button,
    Card,
    CardContent,
    Container,
    FormControl,
    TextField,
    Typography
} from "@mui/material";
import { Loader } from '../loader/Loader';
import { useStyles } from '../../layouts/styles';

const SendPasswordReset: React.FC = observer(() => {
    const { alertStore, authStore } = useContext(RootStoreContext);
    const history = useHistory();
    const [loading, setLoading] = React.useState(false);
    const [email, setEmail] = React.useState<string>('');
    const [error, setError] = React.useState<string | undefined>(undefined);

    const classes = useStyles();

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value);
    }

    const handleSendPasswordReset = () => {
        setLoading(true);
        if (!email || email.length <= 0) {
            setLoading(false);
            setError('Incorrect email provided');
            return;
        }
        authStore.sendResetEmail(email);
        alertStore.success('Password Reset link has been sent.');
        history.push(urlPaths.home);
    };

    useEffect(() => {
        if (authStore.isAuthenticated) {
            history.push(urlPaths.home);
        }
    }, [authStore.isAuthenticated, history]);

    return (
        <Container className={classes.CenteredHalfContainer}>
            <Box sx={{ p: 2 }} />
            <Card>
                <CardContent>
                    <Box sx={{ p: 1 }} />
                    <Typography gutterBottom variant="subtitle1" component="div">
                        Provide your email address and we will send a Password Reset link.
                    </Typography>
                    <Box sx={{ p: 1 }} />
                    <Typography color='error' gutterBottom>
                        {error ?? ''}
                    </Typography>
                    <FormControl sx={{ width: '100%' }}>
                        <TextField required type="email" id="email" name="email" label="Email" variant="outlined" onChange={handleInputChange} value={email} />
                    </FormControl>
                </CardContent>
                <CardContent>
                    <Button variant="contained" color="secondary" onClick={handleSendPasswordReset}>Send Email</Button>
                    <Loader loading={loading} />
                </CardContent>
            </Card>
        </Container>
    );
});

export default SendPasswordReset;
