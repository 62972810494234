import React, { useContext } from 'react';
import { observer } from 'mobx-react-lite';
import { useHistory } from 'react-router';
import { RootStoreContext } from '../../stores/context';
import { rootStore } from '../../stores/root';
import { urlPaths } from '../../layouts/routes';
import { Redirect } from 'react-router-dom';


const Logout: React.FC = observer(() => {
    const { alertStore } = useContext(RootStoreContext);
    const { authStore } = rootStore;
    const history = useHistory();

    authStore.signOut()
        .finally(() => {
            alertStore.info('You are logged out.');
            history.push(urlPaths.home);
            return (
                <Redirect to={urlPaths.home} />
            );
        })

    return (
        <>Logout user...</>
    );
});

export default Logout;
