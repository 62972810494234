import { Box, Button, CircularProgress, Container, FormControl, Grid, TextField, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import React, { useContext } from "react";
import { MainLayout } from "../../layouts/page";
import { useStyles } from "../../layouts/styles";
import { RootStoreContext } from "../../stores/context";
import { isValidEmail } from "../../utils/validators";

const LandingPage: React.FC = observer(() => {
    const { alertStore, subscriberStore } = useContext(RootStoreContext);

    const [email, setEmail] = React.useState('');
    const [error, setError] = React.useState<string | undefined>(undefined);
    const [loading, setLoading] = React.useState(false);
    const [subscribed, setSubscribed] = React.useState(false);

    const classes = useStyles();

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setError(undefined);
        setEmail(event.target.value);
    }

    const handleError = () => {
        alertStore.error('Unable to add you email.');
        setLoading(false);
    }

    const handleAddSubscriber = () => {
        setLoading(true);
        if (!isValidEmail(email)) {
            const message = 'Invalid email address provided!'
            alertStore.error(message);
            setError(message);
            setLoading(false);
            return;
        }
        subscriberStore.getSubscriber(email)
            .then((subscriber: any) => {
                if (subscriber) {
                    const message = 'You have already subscribed!'
                    alertStore.warn(message);
                    setSubscribed(true);
                    setLoading(false);
                    return;
                }

                const newSubscriber = { email, date: new Date() };
                subscriberStore.addSubscriber(newSubscriber)
                    .then(() => {
                        alertStore.success('Thank you for subscribing!');
                        setSubscribed(true);
                        setLoading(false);
                    })
                    .catch(handleError)
            })
            .catch(handleError)
    };

    return (
        <>
            <MainLayout fullWidth overlay disableAuth>
                <Container className={classes.CenteredWideContainer}>
                    <Container maxWidth='xl'>
                        <Box sx={{ p: 3 }}></Box>
                        <Typography variant='h1' align='center' sx={{ fontWeight: 500 }}>
                            Save with Neighbours
                        </Typography>
                        <Box sx={{ p: 1 }}></Box>
                        <Typography variant='overline' component='h3' align='center'>
                            We are redefining how people make purchases
                        </Typography>
                        <Box sx={{ p: 1 }}></Box>
                        <Typography variant='h6' component='h2' align='center'>
                            We find others in your neighbourhood looking to make the same purchase as you. We then
                            work with great businesses that are willing to provide you and the others with a deal, so you can
                            <Typography component='span' variant='inherit' sx={{ fontWeight: 900 }} color='secondary.main'> save</Typography> as a group.
                        </Typography>
                        <Box sx={{ p: 1 }}></Box>
                        <Typography variant='subtitle1' align='center'>
                            Subscribe for early access to
                            <Typography component='span' variant='inherit' sx={{ fontWeight: 900 }} color='primary.main'> Crowd</Typography>
                            <Typography component='span' variant='inherit' sx={{ fontWeight: 900 }} color='secondary.main'>Save</Typography>
                        </Typography>
                        <Box sx={{ p: 1 }}></Box>
                        <Box sx={{ flexGrow: 1 }}>
                            {subscribed ?
                                <Grid container direction='row' justifyContent='center'>
                                    <Grid container item xs={11} sm={10}>
                                        <Box sx={{ bgcolor: 'primary.main', p: 2, width: '100%' }}>
                                            <Typography variant='body1' color='secondary.light' align='center'>
                                                Thank you for subscribing. Look out for our email in your inbox.
                                            </Typography>
                                        </Box>
                                    </Grid>
                                </Grid>
                                :
                                <FormControl fullWidth>
                                    <Grid container direction='row' justifyContent='center'>
                                        <Grid container item xs={11} sm={10}>
                                            <Grid item xs={12} sm={8}>
                                                <TextField fullWidth sx={{ borderRadius: 0 }} required error={!!error} type='email' id='email' name='email' label='Enter your email' variant='outlined' onChange={handleInputChange} value={email} />
                                            </Grid>
                                            <Grid item xs={12} sm={4} >
                                                <Button fullWidth sx={{ p: 1.9, }} variant='contained' color='primary' size='large' disabled={loading} onClick={handleAddSubscriber}>
                                                    {loading ? <CircularProgress size={20} /> : 'Subscribe'}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </FormControl>
                            }
                        </Box>
                    </Container>
                </Container>
                <Box sx={{ m: 0, p: 0, width: '100%', position: 'absolute', transform: 'translateY(-100%)', }}>
                    <Box sx={{ overflow: 'hidden', width: '60vw', height: '10vw', verticalAlign: 'bottom', position: 'absolute', top: '-10vw', right: '0' }}>
                        <Box sx={{ bgcolor: 'secondary.main', width: '200vw', height: '200vw', borderRadius: '100%', transform: 'translate(-30%, 0%)' }}></Box>
                    </Box>
                    <Box sx={{ overflow: 'hidden', width: '60vw', height: '10vw', verticalAlign: 'bottom', position: 'absolute', top: '-10vw', }}>
                        <Box sx={{ bgcolor: 'primary.main', width: '200vw', height: '200vw', borderRadius: '100%', transform: 'translate(-45%, 0%)' }}></Box>
                    </Box>
                </Box>
            </MainLayout>
        </>
    )

});
export default LandingPage;