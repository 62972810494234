import { createTheme, responsiveFontSizes } from '@mui/material';
import { red } from '@mui/material/colors';

const theme = createTheme({
    palette: {
        primary: {
            light: '#7e61ff',
            main: '#3333ff',
            dark: '#0000ca',
            contrastText: '#fffbfb',
        },
        secondary: {
            light: '#7eff6c',
            main: '#33ff33',
            dark: '#00ca00',
            contrastText: '#0000ca',
        },
        error: {
            main: red.A400,
        },
        warning: {
            main: '#ED6C02',
        },
        background: {
            default: '#f7fff7',
        },
    },
    typography: {
        // In Chinese and Japanese the characters are usually larger,
        // so a smaller fontsize may be appropriate.
        fontSize: 12,
        button: {
            fontSize: '0.8rem',
        },
    },
});

export default responsiveFontSizes(theme);
