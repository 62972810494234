import { makeAutoObservable } from "mobx";
import { getStorage, FirebaseStorage } from "firebase/storage";
import FirebaseInitService from "./init";
import { IMediaStorageService } from "../interfaces";

class FirebaseMediaStorageService implements IMediaStorageService {
    storage: FirebaseStorage;

    constructor(firebaseInitService: FirebaseInitService) {
        makeAutoObservable(this);
        this.storage = getStorage(firebaseInitService.app);
    }

    getFile = (path: string, uuid: string): any => {

    };

    storeFile = (path: string, file: any): any => {

    };

    removeFile = (path: string, uuid: string): any => {

    };

    replaceFile = (path: string, file: any, uuid: string): any => {

    };

}

export default FirebaseMediaStorageService;