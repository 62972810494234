import FirebaseInitService from "./init";
import { makeAutoObservable } from "mobx";
import {
    Firestore,
    getFirestore,
    query,
    collection,
    doc,
    addDoc,
    getDoc,
    getDocs,
    setDoc,
    where,
    limit,
    orderBy,
    QueryConstraint,
} from "@firebase/firestore";
import { IDatabaseService } from "../interfaces";
import { first } from "../../utils/safe";

export type OrderByDirection = 'desc' | 'asc';

export type QueryOperation = '==' | '!=' | '>' | '>=' | '<' | '<=';

export interface Query {
    name: 'where' | 'limit' | 'orderBy';
}

export class WhereQuery implements Query {
    readonly name = 'where';
    constructor(public field: string, public operation: QueryOperation, public value: string | number | Date) { }
}

export class OrderByQuery implements Query {
    readonly name = 'orderBy';
    constructor(public field: string, public direction: OrderByDirection) { }
}

export class LimitQuery implements Query {
    readonly name = 'limit';
    constructor(public limit: number) { }
}

export class QueryResolver {
    static resolve = (query: Query) => {
        switch (query.name) {
            case 'where':
                const whereQuery = query as WhereQuery;
                return where(whereQuery.field, whereQuery.operation, whereQuery.value);
            case 'orderBy':
                const orderByQuery = query as OrderByQuery;
                return orderBy(orderByQuery.field, orderByQuery.direction);
            case 'limit':
                const limitQuery = query as LimitQuery;
                return limit(limitQuery.limit);
        }
    }
}

class FirebaseDatabaseService implements IDatabaseService {
    db: Firestore;

    constructor(firebaseInitService: FirebaseInitService) {
        makeAutoObservable(this);
        this.db = getFirestore(firebaseInitService.app);
    }

    addDocument = async (name: string, data: any) => {
        const reference = collection(this.db, name);
        return (await addDoc(reference, data)).id;
    }

    getDocument = async (name: string, uuid: string) => {
        const result = await getDoc(doc(this.db, name, uuid));
        console.debug('>> getDocument result', result?.data);
        return result?.data;
    }

    getAllDocuments = async (name: string) => {
        const result = await getDocs(collection(this.db, name));
        console.debug('>> getAllDocuments result', result.docs);
        return result.docs;
    }

    queryDocument = async (name: string, queries: Query[]) => {
        queries.push(new LimitQuery(1));
        const doc = first(await (this.queryDocuments(name, queries)));
        console.debug('>> queryDocument result', doc?.data);
        return doc?.data;
    }

    queryDocuments = async (name: string, queries: Query[]) => {
        const reference = collection(this.db, name);
        const result = await getDocs(query(reference, ...queries.map<QueryConstraint>(QueryResolver.resolve)));
        console.debug('>> queryDocuments result', result.docs);
        return result.docs;
    }

    udpateDocument = async (name: string, uuid: string, data: any) => {
        const reference = collection(this.db, name, uuid);
        return await setDoc(doc(reference), data, { merge: true });
    }
}

export default FirebaseDatabaseService;