import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { RootStoreContext } from './stores/context';
import { rootStore } from './stores/root';
import { ThemeProvider } from '@mui/material';
import { BrowserRouter } from 'react-router-dom';
import theme from './theme';
import ContextInitializer from './components/initializer/Initializer';

ReactDOM.render(
    <RootStoreContext.Provider value={rootStore}>
        <ContextInitializer rootStore={rootStore}>
            <ThemeProvider theme={theme}>
                <BrowserRouter basename=''>
                    <App />
                </BrowserRouter>
            </ThemeProvider>
        </ContextInitializer>
    </RootStoreContext.Provider>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
