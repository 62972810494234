import React from 'react';
import { Dimensions } from '../interfaces/common';

export type LogoColor = 'main' | 'white' | 'black' | 'grey' | 'contrast1' | 'contrast2';

export interface LogoOptions extends Dimensions {
    logoColor?: LogoColor;
}

export const CrowdSaveWordmark: React.FC<LogoOptions> = ({ width, height, logoColor, }) => {
    const watermarkUrl = !logoColor || logoColor === 'main' ? '/wordmark.png' : `/wordmark-${logoColor}.png`;
    return (
        <img src={watermarkUrl} height={height} width={width} alt='CrowdSave' />
    );
};

export const CrowdSaveLogo: React.FC<LogoOptions> = ({ width, height, logoColor, }) => {
    const logoUrl = logoColor === 'main' ? '/icon.png' : `/icon-${logoColor}.png`;
    return (
        <img src={logoUrl} height={height} width={width} alt='CrowdSave' />
    );
};