import React, { useContext, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../stores/context';
import { useHistory } from 'react-router';
import { urlPaths } from '../../layouts/routes';

const PrivateAccess: React.FC = observer(({ children }) => {
    const { authStore } = useContext(RootStoreContext);
    const history = useHistory();

    useEffect(() => {
        if (!authStore.isAuthenticated) {
            history.push(urlPaths.auth.login);
        }
    }, [authStore.isAuthenticated, history]);

    return <>{children}</>;
});

export default PrivateAccess;