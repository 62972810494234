import { observer } from "mobx-react-lite";
import React from "react";
import SendPasswordReset from "../../components/auth/SendPasswordReset";
import { MainLayout } from "../../layouts/page";

const SendPasswordResetPage: React.FC = observer(() => {

    return (
        <>
            <MainLayout>
                <SendPasswordReset />
            </MainLayout>
        </>
    )
});
export default SendPasswordResetPage;