import { makeAutoObservable } from "mobx";
import { User } from "../interfaces/user";
import { WhereQuery } from "../services/firebase/database";
import { IDatabaseService } from "../services/interfaces";

const COLLECTION_NAME = 'users';

class UserStore {
    databaseService: IDatabaseService;

    constructor(databaseService: IDatabaseService) {
        makeAutoObservable(this);
        this.databaseService = databaseService;
    }

    createUser = (user: User) => {
        return this.databaseService.addDocument(COLLECTION_NAME, user);
    }

    findUser = (email: string) => {
        return this.databaseService.queryDocument(COLLECTION_NAME, [new WhereQuery('email', '==', email)]);
    }
}

export default UserStore;