import './Initializer.css';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { Loader } from '../loader/Loader';
import { RootStore } from '../../stores/root';
import { Grid } from '@mui/material';

export interface InitializerProps {
    rootStore: RootStore;
}

const ContextInitializer: React.FC<InitializerProps> = observer(({ children, rootStore }) => {
    const [loading, setLoading] = useState(true);
    const { authStore } = rootStore;

    useEffect(() => {
        setLoading(!authStore.isReadyForAuth);
    }, [authStore.isReadyForAuth]);

    if (loading) {
        return <>
            <Grid container spacing={2} className="Initializer-Container"
                direction="column"
                justifyContent="center"
                alignItems="center">
                <Grid item>
                    <Loader loading={loading} />
                </Grid>
            </Grid>
        </>
    }

    return <>{children}</>;
});

export default ContextInitializer;
