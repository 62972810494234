import { makeAutoObservable, computed, observable, action } from "mobx";
import { UserAuthDetails } from "../interfaces/user";
import { IAuthService } from "../services/interfaces";

class AuthStore {
    @observable authService: IAuthService;

    constructor(authService: IAuthService) {
        makeAutoObservable(this);
        this.authService = authService;
    }

    @computed
    get isReadyForAuth() {
        return this.authService.isReadyForAuth;
    }

    @computed
    get isAuthenticated() {
        return this.authService.isAuthenticated;
    }

    @action
    signIn(email: string, password: string) {
        return this.authService.signIn(email, password);
    }

    @action
    signOut() {
        return this.authService.signOut();
    }

    createUser(email: string, password: string) {
        return this.authService.createUser(email, password)
    }

    updateUser(newAuthDetails: UserAuthDetails) {
        return this.authService.updateUser(newAuthDetails);
    }

    sendVerificationEmail() {
        return this.authService.sendEmailVerification();
    }

    sendResetEmail(email: string) {
        return this.authService.resetPassword(email);
    }
}

export default AuthStore;