import { makeAutoObservable } from "mobx";
import { IAnalyticsService } from "../services/interfaces";

class AnalyticsStore {
    analyticsService: IAnalyticsService;

    constructor(analyticsService: IAnalyticsService) {
        makeAutoObservable(this);
        this.analyticsService = analyticsService;
    }

    log = (type: string) => {
        return this.analyticsService.log(type);
    }
}

export default AnalyticsStore;