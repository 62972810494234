import { Theme } from "@mui/material";
import { createStyles, makeStyles } from '@mui/styles';


export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            margin: 'auto',
        },
        CenteredContainer: {
            maxWidth: '70%',
            margin: 'auto',
            minHeight: '100vh',
            paddingTop: '150px',
            paddingBottom: '150px',
            [theme.breakpoints.down("md")]: {
                maxWidth: '100%',
                paddingTop: '100px',
                paddingBottom: '100px',
            }
        },
        CenteredHalfContainer: {
            maxWidth: '50%',
            margin: 'auto',
            paddingTop: '150px',
            paddingBottom: '150px',
            [theme.breakpoints.down("md")]: {
                maxWidth: '100%',
                paddingTop: '100px',
                paddingBottom: '100px',
            }
        },
        CenteredWideContainer: {
            maxWidth: '80%',
            margin: 'auto',
            minHeight: '100vh',
            paddingTop: '150px',
            paddingBottom: '150px',
            [theme.breakpoints.down("md")]: {
                maxWidth: '100%',
                paddingTop: '100px',
                paddingBottom: '100px',
            }
        },
    })
);