import React from 'react';
import { observer } from 'mobx-react-lite';
import { Box } from '@mui/material';
import { PageHeader, PageHeaderProps } from './header';
import { PageFooter } from './footer';

interface MainLayoutProps extends PageHeaderProps {
    fullWidth?: boolean;
    fullHeight?: boolean;
}

export const MainLayout: React.FC<MainLayoutProps> = observer(({ children, isAuthPage, fullWidth, fullHeight, disableAuth, overlay, logoColor }) => {
    return (
        <>
            {/* Body */}
            <Box>
                <PageHeader isAuthPage={isAuthPage} overlay={overlay} logoColor={logoColor} disableAuth={disableAuth} />
                <Box component='main'>
                    <Box maxWidth={fullWidth ? 'xl' : 'lg'} minHeight='100vh' sx={{ margin: 'auto' }}>
                        {children}
                    </Box>
                </Box>
            </Box>
            {/* Footer */}
            <PageFooter />
        </>
    );
});
