import React, { useContext, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../stores/context';
import { AppBar, Avatar, Box, Button, Container, Grid, IconButton, Menu, MenuItem, Toolbar, Typography, useScrollTrigger } from '@mui/material';
import { CrowdSaveWordmark, LogoColor } from './logo';
import AccountCircle from '@mui/icons-material/AccountCircle';
import { navs, urlPaths } from './routes';
import { useHistory } from 'react-router';
import MenuIcon from '@mui/icons-material/Menu';

export type HeaderPosition = 'fixed' | 'static' | 'absolute' | 'sticky' | 'relative';

export interface PageHeaderProps {
    overlay?: boolean;
    isAuthPage?: boolean;
    logoColor?: LogoColor;
    disableAuth?: boolean;
}

export const PageHeader: React.FC<PageHeaderProps> = observer(({ isAuthPage, overlay, logoColor, disableAuth }) => {
    const history = useHistory();
    const { alertStore, authStore } = useContext(RootStoreContext);
    const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
    const [anchorElProfile, setAnchorElProfile] = React.useState<null | HTMLElement>(null);

    const scrollTrigger = useScrollTrigger();

    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElProfile(event.currentTarget);
    };

    const handleProfileMenuClose = () => {
        setAnchorElProfile(null);
    };

    const handleLogin = () => {
        history.push(urlPaths.auth.login);
    }

    const handleLogout = () => {
        setAnchorElProfile(null);
        authStore.signOut()
            .then(() => {
                alertStore.success('User logged out.');
                handleHome();
            })
            .catch(console.log);
    }

    const handleHome = () => {
        history.push(urlPaths.home);
    }

    useEffect(() => {
        console.debug('>> Header found user is auth\'d:', authStore.isAuthenticated);
    }, [authStore.isAuthenticated])

    return (
        <AppBar
            elevation={scrollTrigger ? 2 : 0}
            sx={{
                bgcolor: !scrollTrigger ? 'transparent' : 'background.default',
                pt: !scrollTrigger ? 2 : 0,
                pb: !scrollTrigger ? 2 : 0
            }}>
            <Container maxWidth="xl">
                <Toolbar sx={{ paddingLeft: 0, paddingRight: 0 }}>
                    {/* Logo */}
                    <Grid sx={{ flexGrow: 2 }}>
                        <Button onClick={handleHome} sx={{ p: 0, m: 0 }}>
                            <CrowdSaveWordmark height={40} logoColor={logoColor} />
                        </Button>
                    </Grid>

                    {/* Navs for small screen */}
                    <Box sx={{ flexGrow: 0, display: { xs: 'flex', md: 'none' } }}>
                        <IconButton
                            size="large"
                            aria-label="display app menu burger icon"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                        >
                            <MenuIcon sx={{ color: 'primary.main' }}/>
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: { xs: 'block', md: 'none' },
                            }}
                        >
                            {navs.map((nav) => (
                                <MenuItem key={nav.title} onClick={() => history.push(nav.urlPath)} sx={{ color: 'primary.main' }}>
                                    <Typography textAlign="center">{nav.title}</Typography>
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box>

                    {/* Navs */}
                    <Box sx={{ flexGrow: 0, display: { xs: 'none', md: 'flex' } }}>
                        {navs.map((nav) => (
                            <MenuItem key={nav.title} onClick={() => history.push(nav.urlPath)} sx={{ color: 'primary.main' }}>
                                <Typography textAlign="center">{nav.title}</Typography>
                            </MenuItem>
                        ))}
                        <Box sx={{ p: 2 }} />
                    </Box>

                    {/* Profile Menu */}
                    {!disableAuth && (
                        authStore.isAuthenticated ?
                            <div>
                                <IconButton
                                    size="large"
                                    aria-label="display profile menu for current user"
                                    aria-controls="profile-menu-appbar"
                                    aria-haspopup="true"
                                    onClick={handleProfileMenuOpen}
                                    color="inherit"
                                >
                                    <Avatar alt="New User" src="/static/images/avatar/2.jpg" sx={{ bgcolor: 'primary.main', width: 30, height: 30  }} />
                                    {/* If we don't have an avatar URL use the one bellow */}
                                    <AccountCircle sx={{ color: 'primary.main', width: 30, height: 30  }} />
                                </IconButton>
                                <Menu
                                    id="profile-menu-appbar"
                                    anchorEl={anchorElProfile}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'right',
                                    }}
                                    keepMounted
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    open={Boolean(anchorElProfile)}
                                    onClose={handleProfileMenuClose}
                                >
                                    <MenuItem onClick={handleHome}>Profile</MenuItem>
                                    <MenuItem onClick={handleHome}>Activity</MenuItem>
                                    <MenuItem onClick={handleHome} divider>Settings</MenuItem>
                                    <MenuItem onClick={handleLogout} color='warning.main'>Logout</MenuItem>
                                </Menu>
                            </div>
                            : !isAuthPage && (
                                <div>
                                    <Button onClick={handleLogin} variant="contained" color="secondary">Login</Button>
                                </div>)
                    )}
                </Toolbar>
            </Container>
        </AppBar >
    );
});
