import { makeAutoObservable } from "mobx";
import { NewBusiness } from "../interfaces/business";
import { IDatabaseService } from "../services/interfaces";

const COLLECTION_NAME = 'businesses';

class BusinessStore {
    databaseService: IDatabaseService;

    constructor(databaseService: IDatabaseService) {
        makeAutoObservable(this);
        this.databaseService = databaseService;
    }

    createBusiness = (business: NewBusiness) => {
        return this.databaseService.addDocument(COLLECTION_NAME, business);
    }
}

export default BusinessStore;