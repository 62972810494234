import { makeAutoObservable, computed, observable, action } from "mobx";
import {
    Auth,
    getAuth,
    signOut,
    updateProfile,
    updatePassword,
    onAuthStateChanged,
    sendEmailVerification,
    sendPasswordResetEmail,
    signInWithEmailAndPassword,
    createUserWithEmailAndPassword,
} from "firebase/auth";
import { IAuthService } from '../interfaces'
import { AuthStatus, UserAuthDetails } from "../../interfaces/user";

const AUTH_USER_KEY = 'authUser';

class FirebaseAuthService implements IAuthService {
    // TODO: handle all promises and return our domain objects not firebase's
    @observable authStatus: AuthStatus = AuthStatus.UNKNOWN;
    @observable authService: Auth;

    constructor() {
        makeAutoObservable(this);
        this.authService = getAuth();
        this.listenToAuthStatus();
    }

    @computed
    get isReadyForAuth() {
        return this.authStatus !== AuthStatus.UNKNOWN;
    }

    @computed
    get isAuthenticated() {
        return AuthStatus.SIGNED_IN === this.authStatus;
    }

    @action
    setStatus(newStatus: AuthStatus) {
        this.authStatus = newStatus;
    }

    createUser = (email: string, credential: string) => {
        return createUserWithEmailAndPassword(this.authService, email, credential);
    }

    @action
    signIn = (email: string, credential: string) => {
        return signInWithEmailAndPassword(this.authService, email, credential);
    }

    @action
    signOut = () => {
        return signOut(this.authService);
    }

    getCurrentUser = () => {
        const user = this.authService.currentUser;
        if (user !== null) {
            return user;
        }
    }

    sendEmailVerification = () => {
        if (this.authService.currentUser) return sendEmailVerification(this.authService.currentUser);
    }

    updatePassword = (email: string, newCredential: string) => {
        if (this.authService.currentUser && this.authService.currentUser.email === email)
            return updatePassword(this.authService.currentUser, newCredential);
    }

    resetPassword = (email: string) => {
        return sendPasswordResetEmail(this.authService, email)
            .catch((err: any) => {
                console.debug('>> Error while sending Password Reset email', err)
            });
    }

    updateUser = (newAuthDetails: UserAuthDetails) => {
        if (this.authService.currentUser)
            return updateProfile(this.authService.currentUser, newAuthDetails);
    }

    private listenToAuthStatus = () => {
        console.debug('Listening to Auth status');
        onAuthStateChanged(this.authService, user => {
            console.debug('>> Auth status has changed');
            localStorage.removeItem(AUTH_USER_KEY);
            if (user) {
                console.debug('User is signed in');
                console.debug('>> Current user', user);
                this.setStatus(AuthStatus.SIGNED_IN);
                localStorage.setItem(AUTH_USER_KEY, JSON.stringify(user));
            } else {
                console.debug('User is not signed in');
                this.setStatus(AuthStatus.SIGNED_OUT);
            }
        });
    }
}

export default FirebaseAuthService;